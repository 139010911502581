import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { CallToAction } from "../components/CTA"

const ProceduresQueryPage = ({ pageContext, location, data }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext
  let allProcedures = data.allProceduresJson.nodes
  let featured = []
  let minor = []

  allProcedures.forEach(p => {
    if (p.procedureLevel === "featured") {
      featured.push(p)
    }
    if (p.procedureLevel === "minor") {
      minor.push(p)
    }
  })

  let schemaData
  if (language === "en") {
    schemaData = {
      presets: post.schemas.presets,
      customSchema: post.schemas.custom,
      dateModified: pageContext.dateModified,
      language,
      metaTitle: metaTitle,
      description: metaDescription,
      path: location.pathname,
      productontologyRelations: post.productontologyRelations
    }
  }

  return (
    <Layout language={language} navSpacer className="procedure-query">
      <SEO
        title={metaTitle}
        description={metaDescription}
        schemaData={schemaData}
        pathname={location.pathname}
        lang={language}
      />
      <Section>
        <Columns sideColumnsSize={4}>
          <div className="column px-40--mobile">
            <Text as="h1" text={post.heading} />
            <Text useStringReplace text={post.topBlurb} />
          </div>
        </Columns>
      </Section>

      <Section>
        <Columns sideColumnsSize={2}>
          <div className="column">
            <div className="procedure-query__grid">
              {featured.map(p => (
                <ProcedureCard
                  key={p.name}
                  hasThisPage={p.hasThisPage}
                  isFeatured={p.procedureLevel === "featured"}
                  slug={`/${p.title}`}
                  image={p.thumbnailPublicId}
                  name={p.name}
                  blurb={p.blurb}
                />
              ))}
            </div>
          </div>
        </Columns>
      </Section>

      {minor.length >= 1 && (
        <Section>
          <Columns sideColumnsSize={2}>
            <div className="column">
              <Text
                as="h3"
                className="mt-1 mb-2 px-40--mobile"
                text={post.additionalProceduresHeading}
              />
              <div className="procedure-query__grid">
                {minor.map(p => (
                  <ProcedureCard
                    headingClass="mt-0"
                    key={p.name}
                    hasThisPage={p.hasThisPage}
                    isFeatured={p.procedureLevel === "featured"}
                    slug={`/${p.title}`}
                    image={p.thumbnailPublicId}
                    name={p.name}
                    blurb={p.blurb}
                  />
                ))}
              </div>
            </div>
          </Columns>
        </Section>
      )}

      <CallToAction
        colorBack
        heading={post.callToAction.heading}
        blurb={post.callToAction.blurb}
        buttons={post.callToAction.buttons}
      />
    </Layout>
  )
}

const ProcedureCard = ({
  hasThisPage,
  isFeatured,
  slug,
  image,
  name,
  blurb,
  headingClass
}) => {
  return (
    <div className="procedure-query__card">
      <WithLink
        noFade
        slug={hasThisPage ? slug : false}
        title={`Learn More About ${name}`}>
        {isFeatured && (
          <Image
            className={hasThisPage ? "hover-scale-up" : ""}
            publicId={image}
          />
        )}
      </WithLink>

      <WithLink
        slug={hasThisPage ? slug : false}
        title={`Learn More About ${name}`}>
        <Text as="h2" className={headingClass + " px-40--mobile"} text={name} />
      </WithLink>
      <Text className="px-40--mobile" as="p" text={blurb} />
    </div>
  )
}

const WithLink = ({ slug, children, title, noFade }) => {
  if (slug) {
    const styles = {}
    if (noFade) {
      styles.opacity = 1
    }
    return (
      <Link style={styles} to={slug} title={title}>
        {children}
      </Link>
    )
  }
  return <>{children}</>
}

export const pageQuery = graphql`
  query proceduresQueryPageEs(
    $title: String!
    $language: String!
    $category: String
  ) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        additionalProceduresHeading
        productontologyRelations
        schemas {
          presets
          custom
        }
        callToAction {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
      }
    }
    allProceduresJson(
      filter: {
        isHiddenQueryPage: { eq: false }
        category: { eq: $category }
        language: { eq: $language }
      }
      sort: { fields: order }
    ) {
      nodes {
        ...ProcedureQuery
      }
    }
  }
`

export default ProceduresQueryPage
